.footer {
  flex-shrink: 0;
  clear: both;
  height: 127px;
  background: #30aec7 url(/img/footer/footer-bg.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  box-shadow: 0 -15px 80px white;
  &__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  &__list {
    display: block;
    margin-bottom: 20px;
  }
  &__info ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 0 5px;
    margin: 0;
  }
  &__info li {
    display: block;
    margin: 0 10px;
  }
  &__info a {
    color: #fff;
    text-decoration: none;
  }
}

@media screen and (max-width: 800px) {
  .footer__inner .logo img {
    width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .footer__info ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 0 15px;
    margin: 0;
  }
}

@media screen and (max-width: 460px) {
  .footer__info ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (max-width: 460px) {
  .footer__info li {
    margin: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 20px;
    height: auto;
  }

  .footer .logo {
    margin-bottom: 10px;
  }

  .footer .logo img {
    max-width: 100%;
  }

  .footer__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 460px) {
  .footer {
    padding: 20px 20px 60px;
  }

  .footer__info {
    text-align: center;
  }
}
