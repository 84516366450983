.alert-modal {
  position: absolute;
  top: 2%;
  right: 1%;
  border: 2px solid rgb(136, 136, 136);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 20px;

  &_row {
    display: flex;
    width: 400px;
    margin-left: 20px;

    > span {
      margin-right: 10px;
      > svg {
        width: 40px;
        height: 40px;
        margin-top: 22px;

        path {
          fill: #ba0096;
        }
      }
    }
  }

  &_description {
    margin-top: 30px;
    color: #333;

    > a {
      color: #00799f;
    }
  }

  &_submit {
    align-items: center;
    margin-top: 24px;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .close-modal {
    border: none;
    background: transparent;
    color: #2875a1;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      display: inline-flex;
      vertical-align: middle;
      text-transform: uppercase;
    }
    > span {
      margin-left: 8px;
      font-size: 34px;
      margin-top: 10px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 480px) {
  .alert-modal {
    padding: 12px;

    &_row {
      width: 95%;
    }
  }
}

@media screen and (max-width: 375px) {
  .alert-modal {
    padding: 8px;

    &_row {
      width: 100%;
      margin-left: 8px;
      margin-top: 4px;
      margin-bottom: 4px;

      > span {
        margin-right: 6px;
        > svg {
          width: 24px;
          height: 24px;
          margin-top: 30px;
        }
      }
    }

    &_description {
      font-size: 16px;
    }
  }
}

.ReactModal__Overlay {
  z-index: 99;
}
