.error_resource-modal {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
    height: 90px;
    margin: 0 20px;
    z-index: 3;

    > span {
      margin-right: 10px;
      > svg {
        width: 40px;
        height: 40px;
        margin-top: 20px;

        path {
          fill: #ba0096;
        }
      }
    }
  }

  &-description {
    color: #333;
    text-align: justify;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
      text-align: left;
    }

    > a {
      color: #ba0096;
      text-decoration: none;
    }
  }

  &_submit {
    align-items: center;
    margin-top: 24px;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .close-modal {
    border: none;
    background: transparent;
    color: #444;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      display: inline-flex;
      vertical-align: middle;
      text-transform: uppercase;
    }
    > span {
      margin-left: 8px;
      font-size: 34px;
      margin-top: 10px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 480px) {
  .error_resource-modal {
    top: 2%;
    right: 1%;
    padding: 12px;

    &-row {
      min-width: 95%;
    }
  }
}

@media screen and (max-width: 375px) {
  .error_resource-modal {
    padding: 8px;

    &-row {
      width: 100%;
      margin-left: 8px;
      margin-top: 4px;
      margin-bottom: 4px;

      > span {
        margin-right: 6px;
        > svg {
          width: 24px;
          height: 24px;
          margin-top: 32px;
        }
      }
    }

    &-description,
    &-link {
      font-size: 16px;
    }
  }
}

.modal-error {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid #ba0096;
  background: white;
  overflow: auto;
  border-radius: 12px;
  outline: none;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 320px;
  text-align: center;
  & .close-modal {
    border: none;
    background: transparent;
    color: #ba0096;
    font-size: 34px;
    position: absolute;
    right: 5px;
    top: 0px;
  }
  & .error_resource-modal-description {
    margin-top: 10px;
    width: 100%;
  }
  & .error_resource-modal-row {
    margin-left: 0;
    text-align: center;
  }
  & .error_resource-modal-row > span > svg path {
    fill: #ba0096;
  }
}
