.header {
  position: relative;
  padding: 8px 0 15px;
  background-size: 100% auto !important;
  &_user-name {
    margin-right: 8px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #3fb4c4;
    opacity: 0.5;
  }
  & .container {
    z-index: 1;
  }
  .page-logo {
    position: relative;
    margin-top: 15px;
    display: flex;
    .logo {
      display: flex;
      align-items: flex-end;
      color: white;
      font-size: 36px;
      font-weight: 100;
      text-decoration: none;
      margin-bottom: 10px;
      margin-left: 100px;
      height: max-content;
      align-self: flex-end;
      @media screen and (max-width: 1400px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 25px;
      }
      @media screen and (max-width: 640px) {
        font-size: 22px;
        margin-left: 20px;
      }
      @media screen and (max-width: 460px) {
        font-size: 18px;
        margin-left: 10px;
      }
    }
    .user-avatar {
      padding-bottom: 10px;
      .sb-avatar {
        width: 120px !important;
        height: 120px !important;
        border: 4px solid white;
        @media screen and (max-width: 640px) {
          width: 100px !important;
          height: 100px !important;
        }
      }
      .sb-avatar__image,
      .sb-avatar__text {
        width: 120px !important;
        height: 120px !important;
        @media screen and (max-width: 640px) {
          width: 100px !important;
          height: 100px !important;
        }
      }

      .user-avatar-inner > div {
        margin-left: -3px;
      }

      .avatarName {
        position: absolute;
        width: 120px;
        height: 120px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 70px;
        color: white;
        @media screen and (max-width: 640px) {
          width: 100px;
          height: 100px;
          font-size: 50px;
        }
      }
    }
    .carehub-avatar-group {
      position: absolute;
      display: flex;
      left: 100px;
      @media screen and (max-width: 640px) {
        left: 60px;
      }

      .carehub-avatar {
        transition: 0.3s all;
        .sub-avatar {
          margin-right: -10px;
          padding-bottom: 8px;
          transition: 0.3s all;
          overflow: hidden;
          cursor: pointer;
          @media screen and (max-width: 640px) {
            width: 40px !important;
            height: 40px !important;
          }
        }
        &:hover > .sub-avatar {
          border: 4px solid #00698c !important;
          border-radius: 50%;
          font-size: 30px;
        }
        &:hover .avatarName {
          transform: scale(1.2);
        }

        .avatarName {
          position: absolute;
          width: 50px;
          height: 50px;
          top: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          transition: 0.3s all;
          cursor: pointer;
          @media screen and (max-width: 640px) {
            width: 40px;
            height: 40px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.viewas-element + .header {
  margin-top: 36px;
  padding: 20px 0 15px;
  @media screen and (max-width: 1440px) {
    min-height: auto;
    padding: 30px 0;
    background-size: auto 100% !important;
  }
  @media screen and (max-width: 640px) {
    margin-top: 72px;
  }
  @media screen and (max-width: 470px) {
    padding: 0px 0 30px;
  }
}

.container-header {
  max-width: 1334px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.landing-header {
  background: #fff;
  padding: 0;
  &::after {
    display: none;
  }
  .helpful-links {
    border-bottom: 1px solid #4fa6c6;
    padding: 10px 0 14px;
    margin-bottom: 30px;
    @media screen and (max-width: 560px) {
      margin-bottom: 10px;
    }
    .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;
      background-image: none;
      color: #2c325c;
      font-size: 14px;
    }

    .text-links {
      display: flex;
      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
      }

      .link-item {
        margin-right: 16px;
        cursor: pointer;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          border-right: 1px solid #00a1b9;
          padding-right: 16px;
          @media screen and (max-width: 640px) {
            border-right: none;
            padding-right: 0px;
            padding-bottom: 2px;
            margin-bottom: 2px;
            border-bottom: 1px solid #00a1b9;
          }
        }
      }
    }

    .icon-links {
      display: flex;
      color: #fff;

      .link-item {
        background-color: #2596be;
        width: 23px;
        height: 23px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        color: #fff;

        i {
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .logo-login {
    display: flex;

    .logo {
      width: 50%;
      text-decoration: none;

      img {
        margin-top: 24px;
      }

      .short-tagline {
        color: #000;
        font-size: 27px;
        font-weight: 600;
        margin-left: 35px;
        font-style: italic;
      }
    }

    .login-signup {
      text-align: right;
      width: 50%;
      margin-bottom: 6px;
      height: 137px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      img {
        width: 275px;
      }
    }

    .login-signup-buttons {
      margin-bottom: 31px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 0px;
      }

      button {
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        padding: 12px 20px;
        border-radius: 6px;
        border: none;
        margin-right: 10px;
        @media screen and (max-width: 1024px) {
          margin: 5px;
        }
        @media screen and (max-width: 980px) {
          margin: 5px 5px 5px 0;
        }
        @media screen and (max-width: 640px) {
          padding: 10px;
        }
        &:last-child {
          margin-right: 0;
        }

        i {
          margin-left: 6px;
          transform: scale(0.8);
        }

        a {
          color: #fff;
          text-decoration: none;
          @media screen and (max-width: 1024px) {
            font-size: 14px;
          }
          @media screen and (max-width: 540px) {
            font-size: 13px;
          }
        }
      }

      .signup {
        background-color: #00a1b9;
      }

      .login {
        background-color: #9e1e87;
        transition: 0.3s all;
        &:hover {
          background-color: #760869;
        }
      }
    }
  }

  .bottom-line {
    background: rgb(30, 91, 137);
    background: linear-gradient(
      90deg,
      rgba(30, 91, 137, 1) 0%,
      rgba(30, 91, 137, 1) 0%,
      rgba(143, 44, 131, 1) 100%
    );
    height: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 2140px) {
  .header {
    .logo {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 1920px) {
  .header {
    .container {
      max-width: 1440px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .header {
    padding: 0 0 16px;
    min-height: 144px;

    &.landing-header {
      padding: 0;
      overflow: hidden;
      min-height: 212px;
      //width: calc(100% + 20px);
    }

    .container {
      max-width: 1280px;
    }

    .logo {
      font-size: 36px;
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 1280px) {
  .header {
    background-size: 130% auto;

    .container {
      max-width: 1024px;
    }

    .logo {
      font-size: 24px;
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 0 0 16px;
    background-size: 135% auto;

    .container {
      max-width: 960px;
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    padding: 0 0 0;
    background-position-x: 30% !important;
    background-size: 180% auto !important;
    &.landing-header {
      padding: 0;
      overflow: inherit;
      min-height: auto;
    }

    .container {
      max-width: 900px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 0 0 16px;
    background-position-x: 20%;
    background-size: 220% auto;
    min-height: 150px;
    &_user-logo {
      margin-right: 5px;
    }

    .container {
      .page-logo {
        min-width: 360px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header {
    padding: 0 0 16px;
    min-height: auto;
    background-position-x: 20% !important;
    background-size: cover !important;
    &_user-logo {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 560px) {
  .header {
    padding: 0 0 12px;
    background-size: 250% auto;
    &_user-name {
      margin-right: 0;
      display: block;
    }
    &_user-logo {
      margin-right: 0px;
    }
    .logo {
      font-size: 20px;
    }

    &.landing-header {
      .inner-wrapper {
        flex-wrap: wrap;
      }

      .helpful-links {
        .text-links {
          width: 100%;
          margin-bottom: 10px;

          .link-item {
            margin-right: 16px;
            cursor: pointer;
            width: 100%;
            font-size: 16px;
          }
        }

        .icon-links .link-item {
          background-color: #2596be;
          width: 30px;
          height: 30px;
        }
      }

      .logo-login {
        flex-wrap: wrap;

        .logo {
          width: 100%;
        }

        .login-signup {
          margin-top: 25px;
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 465px) {
  .header {
    padding: 0 0 20px;

    &_user-logo {
      margin-right: 0px;
    }
    .logo {
      font-size: 16px;
    }

    .container {
      position: relative;
      padding-top: 37px;
      .viewas-title {
        font-size: 14px;
      }

      .header-nav-item {
        padding: 4px 8px;
        span {
          font-size: 14px;
        }
      }

      a.btn-flex {
        font-size: 12px;

        img {
          width: 16px;
        }
      }

      .page-logo {
        min-width: 240px;
      }

      & .btn-heart {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .header {
    padding: 0 0 1px;
    background-position-x: 25%;
    background-size: 500% auto;
    min-height: auto;
    padding-bottom: 20px;
    .nav-block {
      margin-bottom: 0;
    }

    .nav-block-resp {
      display: flex;
    }

    .container {
      .viewas-title {
        font-size: 13px;
      }

      a.btn-flex {
        font-size: 11px;

        img {
          width: 14px;
        }
      }

      .page-logo {
        .logo {
          font-size: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .header {
    padding: 0 0 1px;
    background-size: 500% auto;

    .container {
      .page-logo {
        min-width: 240px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .header {
    padding: 0 0 1px;
    background-size: 520% auto;

    .container {
      .page-logo {
        min-width: 220px;

        .logo {
          font-size: 16px;
        }
      }
    }
  }
}

.container-nav {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  float: right;
  height: 135px;
}

.header-nav-item {
  background-color: #00698c;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  transition: 0.5s;
  text-transform: uppercase;
  margin-right: 8px;
}

.header-nav-item:hover {
  background: #005977;
  transition: 0.5s;
}

.nav-block {
  display: flex;

  @media screen and (max-width: 1440px) {
    margin-top: 8px;
  }
}

.nav-block-resp {
  display: none;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.viewas {
  &-element {
    display: flex;
    margin-top: 3px;
    margin-right: 8px;
  }

  &-title {
    line-height: 36px;
    margin-right: 4px;
  }
  &-value {
    color: #333;
    user-select: none;
    -moz-user-select: none;

    > span {
      padding-top: 7px;
      height: 36px;
      display: inline-block;
    }

    .viewas-select {
      font-size: 0.8rem;
      width: 100%;

      .select__control {
        min-width: 180px;
        font-size: 14px;
        //border-radius: 18px;
        border: 1px solid #00698c;
        height: 28px;

        svg {
          fill: #000;
        }
      }

      .select__value-container {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

.top-line {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9e1e87;
  z-index: 3;
  @media screen and (max-width: 640px) {
    height: auto;
    padding: 0 0 10px;
  }
  & .container {
    width: 100%;
    padding: 0;
  }
  & .viewas-element {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-right: 0;
    margin-left: 5%;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      margin-left: 0%;
    }
  }
  .btn-full-blue {
    background: #ffffff;
    border: 1px solid #00698c;
    color: black;
    height: 29px;
  }
  .viewas-title {
    line-height: 36px;
    margin-left: 10px;
  }
}
