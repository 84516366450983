.add_resource-modal {
  position: absolute;
  top: 2%;
  right: 1%;
  border: 2px solid rgb(136, 136, 136);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 20px;

  &-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-left: 20px;
    z-index: 3;

    > span {
      margin-right: 10px;
      > svg {
        width: 40px;
        height: 40px;
        margin-top: 32px;

        path {
          fill: #ba0096;
        }
      }
    }
  }

  &-description {
    margin-top: 30px;
    color: #333;
    text-align: left;

    > a {
      color: #00799f;
      text-decoration: none;
    }
  }

  &_submit {
    align-items: center;
    margin-top: 24px;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .close-modal {
    border: none;
    background: transparent;
    color: #2875a1;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      display: inline-flex;
      vertical-align: middle;
      text-transform: uppercase;
    }
    > span {
      margin-left: 8px;
      font-size: 34px;
      margin-top: 10px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 480px) {
  .add_resource-modal {
    padding: 12px;

    &-row {
      width: 95%;
    }
  }
}

@media screen and (max-width: 375px) {
  .add_resource-modal {
    padding: 8px;

    &-row {
      width: 100%;
      margin-left: 8px;
      margin-top: 4px;
      margin-bottom: 4px;

      > span {
        margin-right: 6px;
        > svg {
          width: 24px;
          height: 24px;
          margin-top: 32px;
        }
      }
    }

    &-description,
    &-link {
      font-size: 16px;
    }
  }
}

.modal-resource {
  position: absolute;
  left: 50%;
  top: 50%;
  border: 2px solid #25739f;
  background: white;
  overflow: auto;
  border-radius: 12px;
  outline: none;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 320px;
  text-align: center;
  & .close-modal {
    border: none;
    background: transparent;
    color: #2875a1;
    font-size: 34px;
    position: absolute;
    right: 5px;
    top: 0px;
    z-index: 4;
  }
  & .add_resource-modal-description {
    margin-top: 10px;
    width: 100%;
  }
  & .add_resource-modal-row {
    margin-left: 0;
    text-align: center;
  }
  & .add_resource-modal-row > span > svg path {
    fill: #25739f;
  }
}
