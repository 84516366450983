.navbar-menu {
  background-color: #00698c;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  border-radius: 6px;
  transition: 0.5s;
  padding: 0 16px;
  text-transform: uppercase;

  @media screen and (max-width: 465px) {
    font-size: 14px;
    padding: 0 8px;
  }
}

.navbar-menu:hover {
  background: #005977;
  transition: 0.5s;
}

.navbar-menu i {
  color: white;
}
