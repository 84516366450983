.tip-popup {
  position: fixed;
  top: 2%;
  right: 1%;
  border: 2px solid rgb(136, 136, 136);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 20px;
  z-index: 5;

  &.red {
    background: red;
  }

  &.grey {
    background: #eee;
  }

  &.--border-purpure {
    border: 1px solid #ba0096;
    border-radius: 4px;
    padding: 10px 20px;
  }

  &.--border-red {
    border-radius: 4px;
    padding: 10px 20px;
    border: 3px solid #ba0096;
    z-index: 3;
  }
  & .alarm-icon {
    svg {
      width: 40px;
      height: 40px;
      margin-top: 0;
      display: inline-block;
      vertical-align: -25px;
    }
  }
  & .tip-popup_row {
    margin-left: 0px;
  }
  & .popup-bell svg {
    fill: #25739f;
  }

  &_row {
    display: flex;
    align-items: center;
    width: 400px;
    margin-left: 20px;

    > span {
      margin-right: 10px;

      i {
        color: #000;
      }

      > svg {
        width: 40px;
        height: 40px;
        margin-top: 22px;

        path {
          //fill: #ba0096;
        }
      }
    }
  }

  &_description {
    margin-top: 30px;
    color: #333;

    > a {
      color: #00799f;
    }
  }

  &_submit {
    align-items: center;
    margin-top: 24px;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .close-modal {
    border: none;
    background: transparent;
    color: #2875a1;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      display: inline-flex;
      vertical-align: middle;
      text-transform: uppercase;
    }

    > span {
      margin-left: 8px;
      font-size: 34px;
      margin-top: 10px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 480px) {
  .tip-popup {
    top: 2%;
    right: 1%;
    padding: 12px;

    &_row {
      width: 95%;
    }
  }
}

@media screen and (max-width: 375px) {
  .tip-popup {
    padding: 8px;

    &_row {
      width: 100%;
      margin-left: 8px;
      margin-top: 4px;
      margin-bottom: 4px;

      > span {
        margin-right: 6px;

        > svg {
          width: 24px;
          height: 24px;
          margin-top: 30px;
        }
      }
    }

    &_description {
      font-size: 16px;
    }
  }
}
